import Banner from "./components/Banner";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="no-scrollbar">
      <Banner />
      <Footer />
    </div>
  );
}

export default App;
