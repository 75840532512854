import bannerBg from "../../assets/videos/bannerBg.mp4";
import Header from "../Header";

const Banner = () => {
  return (
    <div className="h-screen relative">
      <div className="overlay"></div>
      <Header />
      <video className="w-full h-full object-cover" autoPlay loop muted>
        <source src={bannerBg} type="video/mp4" />
      </video>
      {/* <p className="w-full absolute bottom-20 text-white text-[27px] md:text-[45px] text-center">
        PLAY LOCAL ANYWHERE
      </p> */}
      <div className="absolute w-full bottom-0 top-0 left-0 right-0 flex flex-col justify-center items-center gap-5">
        <p className="text-white text-[18px] md:text-[30px] italic">
          DISCOVER YOUR PASSION
        </p>
        <div className="flex gap-5 items-center justify-center">
          <a
            href="https://sporti.app"
            className="border border-green-600 bg-green-600 italic rounded-sm text-white px-6 py-2 text-xl">
            SPORTI
          </a>
          <a
            href="https://athlos.live"
            className="border border-blue-600 bg-blue-600 italic rounded-sm text-white  px-6 py-2  text-xl">
            ATHLOS
          </a>
        </div>
      </div>
    </div>
  );
};

export default Banner;
