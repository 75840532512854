import Logo from "../../assets/icons/logo.png";

export const Header = () => {
  return (
    <>
      <div className="fixed top-4 md:top-8 left-4 md:left-8 right-4 md:right-8">
        <div className=" flex gap-2 items-center md:items-start text-center flex-col md:flex-row">
          <img className="w-14 md:w-24" src={Logo} alt="logo" />
          <div className="flex-1 justify-center">
            <p className="italic text-white text-[60px] md:text-[100px] md:-ml-[5.5rem]">
              SPOR<span className="text-[#01ff00]">FOR</span>YA
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
