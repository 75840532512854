import LogoLabel from "../../assets/icons/logo-label.png";
// Social media icons
import FacebookIcon from "../../assets/icons/facebook-fill.svg";
import TwitterIcon from "../../assets/icons/twitter-fill.svg";
import LinkedinIcon from "../../assets/icons/linkedin-fill.svg";
import InstagramIcon from "../../assets/icons/instagram-line.svg";
import YoutubeIcon from "../../assets/icons/youtube-fill.svg";
import PinterestIcon from "../../assets/icons/pinterest-line.svg";
import RedditIcon from "../../assets/icons/reddit-line.svg";

const socialMediaIcons = [
  {
    Icon: YoutubeIcon,
    url: "https://www.youtube.com/channel/UCjGELQ2Cn2AdDhR3pI_VA6g",
  },
  {
    Icon: LinkedinIcon,
    url: "https://www.linkedin.com/company/sporforya/?originalSubdomain=es",
  },
  { Icon: TwitterIcon, url: "https://twitter.com/sporforya?lang=es" },
  { Icon: InstagramIcon, url: "https://www.instagram.com/sporforya/?hl=es" },
  { Icon: FacebookIcon, url: "https://www.facebook.com/sporforya" },
  { Icon: PinterestIcon, url: "https://www.pinterest.es/sporforya/" },
  { Icon: RedditIcon, url: "https://www.reddit.com/r/sporforya/" },
];

export const Footer = () => {
  return (
    <div className="bg-[#051827] flex flex-col-reverse md:flex-row gap-14 md:gap-5 items-center justify-between text-white p-8 md:p-14">
      <div className="flex flex-col gap-2">
        <img src={LogoLabel} className="w-[284px]" alt="footer-logo" />
        <span>
          © {new Date().getFullYear()} Sporforya. All rights reserved.
        </span>
      </div>
      <div className="flex flex-col gap-2 justify-center">
        <span className="text-center">Contact us on Social Media</span>
        <div className="flex items-center gap-2">
          {socialMediaIcons.map((icon, index) => (
            <a href={icon?.url} className="text-white" key={index}>
              <img
                src={icon?.Icon}
                className="bg-white rounded-full p-1 w-8 h-8"
                alt={icon?.url}
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
